.numbers{
    position: relative;
    margin-top: 120px;
    margin-bottom: 120px;
    padding-bottom: 50px;
    padding-top: 50px;
    background-color: #05083f;
}

.numbers>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.1;
}

.numbers .header{
    text-align: right;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.numbers .header h1{
    font-size: 25px;
    font-weight: 700;
}


.numbers .header p{
    font-size: 19px;
    direction: rtl;
    width: 33%;
    color: #bab9b9;
    margin-top: 15px;
    margin-bottom: 40px;
}



.numbers .number{
    position: relative;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
}

.numbers .number h3{
    font-size: 62px;
    font-weight: 500;
    color: #fff;
}

.numbers .number h4{
    font-size: 15px;
    color: #fff;
}

.numbers a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 48px;
    font-size: 16px;
    border-radius: 3px;
    color: #05083f;
    background-color: #fff;
    font-weight: 600;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
}

@media(max-width:950px){
    .numbers .header p {
        font-size: 19px;
        direction: rtl;
        width: 100%;
        color: #bab9b9;
        margin-top: 15px;
        margin-bottom: 40px;
    }
}