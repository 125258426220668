.blog{
    margin-top: 120px;
    margin-bottom: 120px;
}

.blog .blog-refer-container{
    background-color: #05083f;
    border-radius: 5px;
    position: relative;
    height: 100%;
    padding: 18px;
    text-align: right;
}

.blog .blog-refer-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    inset: 0;
    object-position: bottom;
}

.blog .blog-refer-container .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #000000c2;
    border-radius: 5px;
}

.blog .blog-refer-container .text{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    color: #fff;
    text-align: right;
}

.blog .blog-refer-container .text h1{
    font-size: 31px;
    text-align: right;
    font-weight: 800;
    margin-bottom: 19px;
}

.blog .blog-refer-container .text p{
    font-size: 14px;
    width: 75%;
    color: #f0f0f0;
    line-height: 23px;
}

.blog .blog-refer-container .text a{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 177px;
    background-color: #05083f;
    border-radius: 4px;
    color: #fff;
    height: 65px;
    font-size: 18px;
    margin-top: 22px;
}

.blog .header{
    margin-bottom: 25px;
}


.blog .header h1{
    text-align: right;
    font-size: 23px;
    font-weight: 600;
    color: #000;
}




.blog .blog-content .img{
    position: relative;
}



.blog .blog-content .img img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 4px;
}

.blog .blog-content .img span{
    position: absolute;
    bottom: 15px;
    left: 15px;
    background-color: #05083f;
    color: #fff;
    width: 125px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 35px;
    font-size: 14px;
    border-radius: 5px;
}

.blog .blog-content h1{
    font-size: 16px;
    text-align: right;
    margin-top: 17px;
    direction: rtl;
    margin-bottom: 24px;
    font-weight: 600;
    color: #000;
    min-height: 48px;
}

.blog .blog-content a{
    font-size: 16px;
    color: #05083f;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



.blog .blog-content h3{
    font-size: 17px;
    color: #727272;
    font-weight: 600;
}


.blog .blog-content h4{
    margin-top: 21px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    color: #000;
    font-weight: 600;
}




@media(max-width:950px){
    .blog-content{
        margin-bottom: 30px;
    }
    
}