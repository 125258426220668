nav{
    width: 100%;
    position: fixed;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #05083f;
    z-index: 15;
}

nav>span{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 9px;
    font-size: 16px;
    color: #04073f82;
}

nav>span.left-shapes{
    left: 55px;
    flex-direction: row-reverse;
}

nav>span.right-shapes{
    right: 10px;
    
}



nav>span span{
    display: flex;
    width: 4px;
    height: 4px;
    background-color: #04073f82;
    border-radius: 50%;
}


nav .nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

nav .nav-content .logo img{
    width: 150px;
    padding: 5px;
    border-radius: 4px;
    background-color: #Fff;
}


nav .nav-content .nav-elements{
    display: flex;
    flex-direction: row;
    align-items: center;
}

nav .nav-content .nav-elements>ul{
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 30px;
}

nav .nav-content .nav-elements>ul>li{
    position: relative;
}

nav .nav-content .nav-elements>ul>li>a {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    height: 20px;
}

nav .nav-content .nav-elements>ul>li>a>span{
    display: flex;
    width: 4px;
    height: 4px;
    background-color: #01e079;
    border-radius: 50%;
}


nav .nav-content .nav-elements>ul>li>ul{
    position: absolute;
    padding: 0;
    background-color: #04073f;
    width: 164px;
    height: fit-content;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    padding: 15px;
    font-size: 15px;
    color: #fff;
    gap: 22px;
    border: 1px solid #00e078;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    top: 51px;
}

nav .nav-content .nav-elements>ul>li>ul.active{

    opacity: 1;
    pointer-events: inherit;
    user-select: inherit;
}


nav .nav-content .nav-elements>ul>li>ul>li>a{
    color: #fff;
    font-size: 13px;
}

nav .nav-content .langs{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
}


nav .nav-content .langs a:nth-child(1){
    gap: 5px;
    color: #fff;
    background: rgb(0,224,120);
    background: linear-gradient(132deg, rgba(0,224,120,1) 0%, rgba(0,224,120,0.6924019607843137) 100%);
    width: 150px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 17px;
    background: rgb(0,224,120);
    background: linear-gradient(132deg, rgba(0,224,120,1) 0%, rgba(0,224,120,0.3254551820728291) 100%);
}


nav .nav-content .langs a:nth-child(2) span{
    position: absolute;
    height: 78%;
    width: 87%;
    border-top: 1px dotted #ffffff29;
    top: 5px;
    left: 11px;
    right: 6px;
    border-left: 1px solid #ffffff1c;
    border-right: 1px solid #ffffff1a;
    border-bottom: 1px dotted #ffffff26;
    border-radius: 9px;
    bottom: 18px;
}



#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
    color: #fff;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}


@media(max-width:950px){

    
    #open-nav{
        display: block;
    }

    #close-nav{
        display: block;
    }

    nav .nav-content .nav-elements {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        height: 100%;
        width: 100%;
        justify-content: center;
        z-index: 15015;
    }

    nav .nav-content .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
        margin: 0 !important;

    }

    
    nav .nav-content .nav-elements{
        display: none;
    }

    nav .nav-content .nav-elements ul li a {
        color: #000;
        font-size: 17px;
        font-weight: 500;
    }

    nav {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 3;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid #ffffff21;
    }

    nav .nav-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
    nav.active{
        position: inherit !important;
    }

}

