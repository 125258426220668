.videos-benefits{
    background-image: url("../images/videosback.webp");
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    margin-bottom: 120px;
}


.videos-benefits .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #0006;
}

.videos-benefits .video-play{
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    position: relative;
}

.videos-benefits .video-play h3{
    font-size: 17px;
    color: #8d8d8d;
}

.videos-benefits .video-play h1{
    font-size: 18px;
}


.videos-benefits .video-play a{
    position: relative;
    background-color: #fff;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #05083f;
    z-index: 2;
    margin-top: 50px;
}

/* Define the decreasing animation */
@keyframes decreaseSize {
    0% {
    transform: scale(1);
    }
    50% {
    transform: scale(0.8);
    }
    100% {
    transform: scale(0.8);
    }
}

/* Define the increasing animation */
@keyframes increaseSize {
    0% {
    transform: scale(0.8);
    }
    50% {
    transform: scale(1);
    }
    100% {
    transform: scale(1);
    }
}

/* Apply animations to the pseudo-elements */
.videos-benefits .video-play a::after {
    content: "";
    position: absolute;
    width: 135%;
    height: 135%;
    background-color: #ffffff59;
    border-radius: 50%;
    z-index: -1;
    animation: decreaseSize 3s linear infinite;
}

.videos-benefits .video-play a::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background-color: #ffffff6e;
    border-radius: 50%;
    z-index: -1;
    animation: increaseSize 3s linear infinite;
}



.videos-benefits .videos-benefits-text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    color: #fff;
    min-height: 300px;
    justify-content: center;
    position: relative;
    height: 100%;
    padding: 15px;
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #0000008f;
}


.videos-benefits .videos-benefits-text h1{
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 40px;
}


.videos-benefits .videos-benefits-text ul{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    margin-bottom: 40px !important;
}


.videos-benefits .videos-benefits-text ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 11px;
    direction: rtl;
}

.videos-benefits .videos-benefits-text ul li i{
    background-color: #ffffff;
    color: #000;
    border-radius: 50%;
}

.videos-benefits .videos-benefits-text div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-top: 40px;
}

.videos-benefits .videos-benefits-text div a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 48px;
    font-size: 16px;
    border-radius: 3px;
    color: #05083f;
    background-color: #fff;
    font-weight: 600;
}


.videos-benefits .videos-benefits-text div a:nth-child(2){
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
}

@media(max-width:950px){
    .videos-benefits .videos-benefits-text div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        margin-top: 40px;
    }

    .videos-benefits .video-play {
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        position: relative;
        min-height: 350px;
    }
}