.blog .navigator-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 50px;
    padding-bottom: 30px;
    border-bottom: 1px solid #00000054;
    gap: 31px;
    flex-wrap: wrap;
}

.blog .navigator-page button{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 40px;
    transition: 0.3s;
    border-radius: 3px;
    padding: 10px;
}

.blog .navigator-page button.active{
    display: flex;
    background-color: #05083f;
    border-radius: 3px;
    color: #fff;
}

.blog .navigator-page button:hover{
    display: flex;
    background-color: #05083f;
    border-radius: 3px;
    color: #fff;
}
