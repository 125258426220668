.landing{
    background-image: url("../images/landingback.webp");
    background-position: center;
    background-size: cover;
    padding-top: 70px;
    position: relative;
    padding-bottom: 166px;
}

.landing .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #0000009e;
}

.landing .landing-text{
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    justify-content: center;
    min-height: 600px;
}

.landing .landing-text h1{
    font-size: 49px;
    width: 49%;
    direction: rtl;
    color: #fff;
    font-weight: 700;
}

.landing .landing-text h1 span{
    color: #00e078;
}

.landing .landing-text .data{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-top: 27px;
    margin-bottom: 18px;
}

.landing .landing-text .data div{
    width: 50%;
    min-height: 66px;
}

.landing .landing-text .data span{
    width: 1px;
    background-color: #00e078;
    height: 76px;
}


.landing .landing-text .data div h4{
    font-size: 18px;
    color: #b2b2b2;
}

.landing .landing-text .data div h2{
    font-size: 19px;
    color: #fff;
}

.landing .landing-text .links{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 19px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.landing .landing-text .links a:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 255px;
    height: 65px;
    font-size: 16px;
    border-radius: 3px;
    color: #05083f;
    background-color: #fff;
    font-weight: 600;
}

.landing .landing-text .links a:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 255px;
    height: 65px;
    font-size: 15px;
    border-radius: 3px;
    color: #ffff;
    background-color: transparent;
    font-weight: 600;
    border: 1px solid #fff;
}

.landing .landing-text h3{
    font-size: 15px;
    color: #fff;
    direction: rtl;
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
}


.landing .landing-text h3 span{
    color: #00e078;
}

.landing .call-us a{
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #05083f;
    z-index: 2;
}

/* Define the decreasing animation */
@keyframes decreaseSize {
    0% {
    transform: scale(1);
    }
    50% {
    transform: scale(0.8);
    }
    100% {
    transform: scale(0.8);
    }
}

/* Define the increasing animation */
@keyframes increaseSize {
    0% {
    transform: scale(0.8);
    }
    50% {
    transform: scale(1);
    }
    100% {
    transform: scale(1);
    }
}

/* Apply animations to the pseudo-elements */
.landing .call-us a::after {
    content: "";
    position: absolute;
    width: 135%;
    height: 135%;
    background-color: #ffffff59;
    border-radius: 50%;
    z-index: -1;
    animation: decreaseSize 3s linear infinite;
}

.landing .call-us a::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background-color: #ffffff6e;
    border-radius: 50%;
    z-index: -1;
    animation: increaseSize 3s linear infinite;
}

.landing .down-benefits{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: right;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #05083f69;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    padding-right: 10px;
    padding-left: 10px;
}

.landing .down-benefits .main-benefit{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.landing .down-benefits .main-benefit h1{
    font-size: 23px;
    color: #fff;
    font-weight: 500;
    width: 71%;
}

.landing .down-benefits .main-benefit a{
    color: #00e078;
}


.landing .down-benefits .benefit{
    width: 20%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-align: right;
    justify-content: center;
    gap: 25px;
}


.landing .down-benefits .benefit i{
    font-size: 38px;
    color: #fff;
}

.landing .down-benefits .benefit div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.landing .down-benefits .benefit div h3{
    color: #fff;
    font-size: 19px;
    font-weight: 600;
}

.landing .down-benefits .benefit div p{
    font-size: 15px;
    color: #a4a4a4;
    direction: rtl;
}

@media(max-width:950px){
    .landing .landing-text h1 {
        font-size: 32px;
        width: 100%;
        direction: rtl;
        color: #fff;
        font-weight: 700;
    }

    .landing .landing-text .links {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 19px;
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .landing .landing-text {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        justify-content: flex-start;
        min-height: 814px;
        padding-top: 60px;
    }

    .landing .down-benefits {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        text-align: right;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #05083f69;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        padding-right: 10px;
        padding-left: 10px;
    }
    .landing .down-benefits .main-benefit {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .landing .down-benefits .benefit {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        text-align: right;
        justify-content: center;
        gap: 25px;
    }

    .landing .call-us a {
        position: absolute;
        left: 15%;
        top: 47%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        color: #05083f;
        z-index: 2;
    }
    
}