.services{
    margin-bottom: 120px;
}

.services .header{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    text-align: right;
}
.services .header div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.services .header div h1{
    font-size: 32px;
    font-weight: 700;
    color: #000;
}

.services .header div p{
    margin-top: 15px;
    margin-bottom: 50px;
    color: #313131;
    width: 80%;
    direction: rtl;
}

.services .header a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 50px;
    font-size: 14px;
    border-radius: 3px;
    color: #fff;
    background-color: #05083f;
    font-weight: 600;
    margin-bottom: 50px;
}


.services .service{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
    padding: 15px;
    text-align: right;
    min-height: 352px;
    justify-content: space-between;
    background-color: #05083f12;
    border-radius: 4px;
}

.services .service>i{
    width: 50px;
    height: 50px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 23px;
}

.services .service h1{
    font-size: 22px;
    font-weight: 700;
    color: #000;
}

.services .service p{
    font-size: 15px;
    color: #303030;
    direction: rtl;
}


.services .service a{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 141px;
    background-color: #05083f;
    border-radius: 4px;
    color: #fff;
    height: 50px;
    font-size: 13px;
}



@media(max-width:950px){
    .services .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: right;
    }
}