.page-header{
    min-height: 330px;
}


.page-header .page-header-content{
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 50px;
    padding-top: 187px;
}

.page-header .page-header-content span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 50px;
    background-color: #05083f;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
}


.page-header .page-header-content h1{
    color: #fff;
    direction: rtl;
    font-size: 23px;
    font-weight: 500;
}


.page-header .page-header-content h1.all {
    color: #fff;
    direction: rtl;
    font-size: 54px;
    font-weight: 500;
}


@media(max-width:950px){
    .page-header .page-header-content h1.all {
        color: #fff;
        direction: rtl;
        font-size: 36px;
        font-weight: 500;
    }

    .page-header .page-header-content {
        min-height: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 14px;
        padding-top: 187px;
    }
}