.blog-page{
    margin-top: 120px;
    margin-bottom: 120px;
}


.blog-page .latest-blogs{
    
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 19px;
    text-align: right;
}


.blog-page .latest-blogs .latest-blog h1{
    font-size: 19px;
    font-weight: 600;
    color: #05083f;
}

.blog-page .latest-blogs .latest-blog a{
    font-size: 17px;
    direction: rtl;
    display: flex;
    color: #05083f;
}


.blog-page .tag-words{
    margin-top: 29px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}

.blog-page .tag-words h1{
    font-size: 19px;
    font-weight: 600;
    color: #000;
}

.blog-page .tag-words span{
    margin-bottom: 19px;
    font-size: 16px;
    font-weight: 500;
}

.blog-page .tag-words i{
    color: #05083f;
}


.blog-page .blog-page-content img{
    width: 100%;
    height: 450px;
    object-fit: cover;
    border-radius: 9px;
}

.blog-page .blog-page-content h1{
    text-align: right;
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin-top: 22px;
    margin-bottom: 20px;
}

.blog-page .blog-page-content p{
    text-align: right;
    font-size: 17px;
    line-height: 30px;
    color: #202020;
    direction: rtl;
}


.blog-page .blog-page-content .cat{
    display: flex;
    flex-direction: row;
    gap: 9px;
    margin-top: 29px;
}


.blog-page .blog-page-content .cat h6{
    font-weight: 700;
    color: #000;
    margin: 0;
}


.blog-page .blog-page-content .cat span{
    font-weight: 500;
    color: #05083f;
}


.blog-page .blog-page-content .put-comment form{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 80px;
    width: 100%;
}

.blog-page .blog-page-content .put-comment h1{
    font-size: 18px;
}


.blog-page .blog-page-content .put-comment label{
    font-size: 19px;
    font-weight: 500;
    color: #767676;
    margin-bottom: 13px;
}


.blog-page .blog-page-content .put-comment textarea{
    width: 100%;
    height: 181px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #a0a0a0;
    text-align: right;
    padding: 15px;
    font-size: 16px;
    color: #000;
    direction: rtl;
}


.blog-page .blog-page-content .put-comment button{
    margin-top: 20px;
    width: 150px;
    height: 43px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #05083f;
    color: #fff;
}




.blog-page .blog-page-content .comments .comment .info{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    gap: 11px;
}

.blog-page .blog-page-content h6{
    text-align: right;
    font-weight: 800;
    margin-bottom: 40px;
    margin-top: 23px;
}



.blog-page .blog-page-content .comments{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
}

.blog-page .blog-page-content .comments .comment{
    width: 100%;
}


.blog-page .blog-page-content .comments .comment .info img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}


.blog-page .blog-page-content .comments .comment .info h1{
    font-size: 17px;
}


.blog-page .blog-page-content .comments .comment p{
    margin-top: 20px;
    border-radius: 15px;
    background-color: #eee;
    padding: 33px;
    margin-right: 42px;
}



@media(max-width:950px){
    .blog-page .row{
        flex-direction: column-reverse;
    }
}