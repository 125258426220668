
.login .login-content{
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 101px;
}

.login .login-content form{
    width: 600px;
    border: 1px solid #0000002b;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    min-height: 400px;
}

.login .login-content form .input{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin-top: 30px;
    position: relative;
}

.login .login-content form .input label{
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.login .login-content form .input input{
    height: 40px;
    width: 100%;
    margin-top: 8px;
    border-radius: 34px;
    border: 1px solid #00000069;
    text-align: right;
    font-size: 18px;
    color: #000;
    padding-right: 15px;
}

.login .login-content form>a{
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 18px;
    color: #05083f;
    font-weight: 500;
}

.login .login-content form button{
    width: 100%;
    height: 40px;
    border-radius: 23px;
    background-color: #05083f;
    border: none;
    outline: none;
    color: #fff;
    margin-top: 30px;
}

.login .login-content form .have-account{
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    margin-top: 19px;
}

.login .login-content form .have-account a{
    color: #000;
    font-weight: 500;
}


.login .login-content{
    display: flex;
    flex-direction: column;
}

.login-content h1{
    font-size: 18px;
    font-weight: 800;
    color: #000;
}

.show-password-button{
    position: absolute;
    left: 15px;
    top: 72%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
}

.login-content img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    
}

@media(max-width:950px){
    .login .login-content form {
        width: 100%;
        border: 1px solid #0000002b;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
        min-height: 400px;
    }
    .login .login-content {
        min-height: 356px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* padding-top: 101px; */
        margin-top: 156px;
    }
}