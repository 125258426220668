.benefits{
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #05083f12;
    border-radius: 14px;
}

.benefits .header{
    text-align: right;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.benefits .header div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.benefits .header div h1{
    font-size: 32px;
    font-weight: 700;
    color: #000;
}

.benefits .header div p{
    font-size: 19px;
    width: 73%;
    margin-top: 19px;
    color: #565656;
}

.benefits .header a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 50px;
    font-size: 14px;
    border-radius: 3px;
    color: #fff;
    background-color: #05083f;
    font-weight: 600;
}


.benefits .benefit{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}

.benefits .benefit i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #05083f;
    color: #fff;
}


.benefits .benefit h1{
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin-top: 35px;
    margin-bottom: 20px;
}


.benefits .benefit p{
    font-size: 16px;
    direction: rtl;
    color: #353535;
}

@media(max-width:950px){
    .benefits .header {
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 27px;
    }
}