footer {
    padding-top: 50px;
    padding-bottom: 25px;
    background-color: #05083f;
    position: relative;
}


footer .footer-content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
}

footer .footer-content h1{
    font-size: 19px;
    color: #fff;
    font-weight: 500;
}

footer .footer-content ul{
    padding: 0;
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 18px !important;
}

footer .footer-content ul li a{
    color: #bcbcbc;
}


footer .footer-content .logo{
    margin-bottom: 15px;
}

footer .footer-content .logo img{
    width: 150px;
    padding: 5px;
    background-color: #fff;
    border-radius: 3px;
}

footer .footer-content>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 7px;
    color: #bebebe;
    font-size: 18px;
}


footer .footer-content>h4{
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: 28px;
    color: #fff;
}

footer .footer-content .socials{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 27px;
    color: #fff;
}

footer .footer-content .socials a{
    font-size: 18px;
    color: #fff;
}

footer>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.1;
}


footer .copy-right{
    padding-top: 33px;
    margin-top: 33px;
    border-top: 1px solid #ffffff2b;
    z-index: 3;
    position: relative;
}


footer .copy-right h6{
    color: #949494;
    font-size: 15px;
}

@media(max-width:950px){
    footer .footer-content .logo {
        margin-bottom: 15px;
        margin-top: 35px;
    }
}