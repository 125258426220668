.features{
    margin-top: 120px;
    margin-bottom: 120px;
}



.features .features-img img{
    width: 100%;
    height: 600px;
    object-fit: contain;
}


.features .features-text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}

.features .features-text h1{
    font-size: 31px;
    font-weight: 600;
    direction: rtl;
    color: #000;
}

.features .features-text p{
    margin-top: 15px;
    margin-bottom: 50px;
    color: #313131;
}

.features .features-text .text{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 19px;
    margin-bottom: 40px;
    margin-top: 20px;
}

.features .features-text .text div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.features .features-text .text div h2{
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 500;
    color: #000;
}

.features .features-text .text div p{
    margin: 0;
    width: 80%;
    direction: rtl;
}

.features .features-text .text i{
    height: 80px;
    font-size: 40px;
    color: #000;
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.features .features-text h3{
    font-size: 14px;
    direction: rtl;
    color: #000;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 30px;
}

.features .features-text h3 span{
    color: #13e282;
}

.features .features-text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 50px;
    font-size: 14px;
    border-radius: 3px;
    color: #fff;
    background-color: #05083f;
    font-weight: 600;
    margin-bottom: 50px;
}


@media(max-width:950px){
    .features {
        margin-top: 40px;
        margin-bottom: 120px;
    }

    .features .features-img img {
        width: 100%;
        height: 300px;
        object-fit: contain;
    }
}