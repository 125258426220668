
.about{
    margin-top: 120px;
}
.about .about-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about .about-img img{
    width: 80%;
    height: 600px;
    object-fit: cover;
    border-radius: 4px;
}

.about .about-text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}


.about .about-text h1{
    font-size: 32px;
    font-weight: 700;
    color: #000;
}

.about .about-text p{
    margin-top: 15px;
    margin-bottom: 50px;
    color: #313131;
    width: 80%;
}

.about .about-text ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.about .about-text ul li{
    width: 50%;
    margin-bottom: 21px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 15px;
    font-weight: 800;
    color: #05083f;
}


.about .about-text ul li i{
    background-color: #05083f;
    border-radius: 50%;
    color: #fff;
    padding: 4px;
    font-size: 9px;
}

.about .about-text h3{
    margin-top: 50px;
    font-size: 17px;
    font-weight: 700;
    color: #05083f;
}

.about .about-text h4{
    margin-bottom: 50px;
    font-size: 20px;
    color: #000;
    font-weight: 900;
}

.about .about-text .about-benefits{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.about .about-text .about-benefits .benefit{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 15px;
}

.about .about-text .about-benefits .benefit i{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #05083f12;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.about .about-text .about-benefits .benefit div{
    width: 77%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.about .about-text .about-benefits .benefit div h2{
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.about .about-text .about-benefits .benefit div p{
    margin: 0;
    width: 100%;
}


@media(max-width:950px){
    .about .about-img img {
        width: 100%;
        height: 400px;
        object-fit: cover;
        border-radius: 4px;
        margin-bottom: 50px;
    }

    .about .about-text .about-benefits {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 27px;
    }
}